

























import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import { Notify, Dialog } from 'vant'
export default Vue.extend({
    data() {
        return {
            checked: false,
            loading: false,
            pageNum: 0,
            pageSize: 10,
            total: 0,
            list: [] as any[],
        }
    },
    watch: {
        checked(val) {
            this.list.forEach(e => e.checked = val)
        }
    },
    mounted() {
        this.onInit()
    },
    methods: {
        onInit() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const params = { pageNum, pageSize }
            request.get('/api/tobacco/rest_push_msg/page', params).then(({list, total}) => {
                if(pageNum==1) this.list = list
                else this.list = this.list.concat(list)
                this.total = total
                this.loading = false
            })
        },
        showDesc(item: any) {
            Dialog.alert({
                title: item.title,
                theme: 'round-button',
                closeOnClickOverlay: true,
                confirmButtonColor: '#459E75',
                message: item.content,
            })
        },
        del() {
            if(!this.list || this.list.length == 0) return
            const idList: any[] = []
            this.list.forEach(e => {
                if(e.checked) idList.push(e.id)
            })
            if(idList.length == 0) return Notify({ type: 'warning', duration: 1000, message: '请选择消息' })
            const ids = idList.join(',')
            request.del(`/api/tobacco/rest_push_msg/${ids}`).then(()=> {
                Notify({ type: 'success', duration: 1000, message: '删除成功' })
                this.onInit()
            })
        }
    },
    filters: {
        dtFormat(timestamp: number) {
            return moment(timestamp).format('YYYY/M/D HH:mm:ss')
        }
    }
})
